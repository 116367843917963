.tutorial-image-wrapper {
    text-align: center;
    width: 100%
}
.tutorial-image {
    border: 0.1rem solid #dfe3e8;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 80%
}
.tutorial-image-small {
    width: 300px!important;
}
.tutorial-code-wrapper {
    margin: 30px 0 20px 0!important;
    text-align: center;
}
.tutorial-code-wrapper code {
    background: rgba(0,0,0,.06);
    border: 1px solid rgba(0,0,0,.06);
    border-radius: 3px;
    font-size: 16px;
    margin: 10px 0;
    padding: 5px;
}