.delete-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.delete-wrapper .Polaris-Icon {
    cursor: pointer;
    margin: 0!important;
}

.empty-state-msg {
    margin: 40px 15%;
    text-align: center;
}

.loading-wrapper {
    align-items: center;
    background-color: #000;
    display: none;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    height: 100%;
    width: 100%;
}

.loading-wrapper img {
    height: auto;
    width: 100px;
}

.video-card-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.video-card-form {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
}

.video-card-wrapper {
    cursor: move;
    margin-bottom: 20px;
}

.video-placeholder {
    height: auto;
    width: 100%;
}

.video-placeholder-wrapper {
    position: relative;
    width: 20%;
}